@import '../../../../../../variables';

.sowflow-container {
    min-height: 40vh;
    margin-bottom: 1rem;
    padding: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .sowflow-text-container {
        max-width: 400px;
        margin: 0 1rem 2rem 1rem;

        h2 {
            margin-bottom: 1rem;
        }

        h5 {
            font-size: 110%;
            margin-bottom: 0.5rem;
        }

        p {
            margin-bottom: 1rem;
        }
    }

    .sowflow-img-container {
        width: 400px;
    }
}

@import '../../../../../variables';

.container {
    height: 20vh;
    width: 100vw;
    background-color: $dark-green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;

    .icon-container {
        display: flex;
        width: 300px;
        justify-content: space-around;
    }

    ul li {
        list-style: none;
    }
}

.title {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
}

.icon {
    // transform: scale(1.5);
    
    color: #fff;
    transition: 0.5s ease-in-out;
}

.icon:hover, .icon:focus, .icon:active {
    transform: scale(1.2);
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.footer-icon {
    margin: 0.27rem auto;
}

.flag {
    border-radius: 5px;
    height: 30px;
}

.ams-container {
    // width: 100vw;
    padding: 2rem;
    // display: flex;
    // flex-wrap: wrap;

    h2 {
        margin-bottom: 1rem;
    }

    h5 {
        font-size: 110%;
        margin-bottom: 0.5rem;
    }

    p {
        margin-bottom: 1rem;
    }

    .ams-img-container {
        width: 80vw;
        margin: 0 auto;
        overflow-x: hidden;

        img {
            width: 80vw;
        }
    }

    .ams-text-container {
        width: 80vw;
        margin: 0 auto;
    }
}

.ams_large-container {
    padding: 2rem;
}

.ams_large {
    max-width: 80vw;
    margin: 0 auto;
    position: relative;
    z-index: -1;

    img {
        width: 80vw;
    }

    .ams_large_text {
        z-index: 1;
        position: absolute;
        margin-top: -19rem;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding: 1rem 2rem;
    }
}

.ams-button-container {
    display: flex;
    justify-content: center;
}
@import '../../../../../../variables';

.dinsdag-container {
    min-height: 40vh;
    margin-bottom: 1rem;
    background: $light-green;
    padding: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .dinsdag-text-container {
        width: 500px;
        margin-left: 1.5rem;

        h2 {
            margin-bottom: 1rem;
        }

        h5 {
            font-size: 110%;
            margin-bottom: 0.5rem;
        }

        p {
            margin-bottom: 1rem;
        }
    }

    .dinsdag-img-container {
        width: 400px;
    }
}

@import '../../../../../variables';

.teampage-container {
    min-height: 70vh;
    // width: 100vw;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    
    h1 {
        margin-bottom: 1.2rem;
    }

    .card-container {
        display: flex;
        width: 100vw;
        padding: 0 5rem;
        // justify-content: space-between;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .team-card {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        width: 16.5rem;
        margin-bottom: 2rem;
        // align-items: center;
        // justify-content: center;
        // border: 2px solid gray;
    }

    .card-img {
        display: flex;
        justify-content: center;

        img {
            width: 15rem;
            border-radius: 50%;
            
        }
    }

    .card-text {
        h3 {
            text-align: center;
            margin: 0.75rem;
        }
    }

}

.team-card:nth-child(2) {
    background: $light-green;
}

.team-card:nth-child(1) {
    box-shadow: 2px 8px 2px lightgray;
    background: $light-blue;
}

.team-card:nth-child(3) {
    box-shadow: 2px 8px 2px lightgray;
    background: $light-blue;
}
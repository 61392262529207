@import '../../../../../variables';

.about-container {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    #about-banner {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      img {
        width: 100vw;
      }
    }

    .video-container {
        max-width: 800px;
    }

    .item-text {
        padding: 2.5rem;

        @media (min-width: 1000px) {
          h2, p {
            padding-left: 5rem;
            padding-right: 5rem;
          }
        }
    }
    
    .item-img {
        margin-top: 2rem;
    }

    #wastewater {
      padding: 2rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    #wastewater-img {
      max-width: 500px;
    }

    #wastewater-text {
      max-width: 600px;
    }
}

.transition-img {
    opacity: 1;
    display: block;
    height: 170px;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  #pictures {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
          margin: 0 auto;
      }
  }

  .transition {
    transition: .5s ease;
    opacity: 0;
    width: 100%;
    text-align: center;
  }
  
  .transition_img-container:hover .transition-img {
    opacity: 0.3;
  }
  
  .transition_img-container:hover .transition {
    opacity: 1;
  }
  
  .transition-text {
    background-color: $dark-green;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
  }

  .green {
      background-color: $light-green;
  }
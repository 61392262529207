// Import variables if you have a separate variables file
@import '../../../../../../variables';

.hero-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  color: #fff;
  margin-top: -10vh;
}

.hero-container:before {
  content: '';
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: -2;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.text-container {
  width: 60%;
  margin: 20% 10% 25% 10%;  // Adjusts the position of the text
  text-align: center;
  color: #fff;

  .text {
    padding: 10px 5px;
    background-color: rgba(0, 0, 0, 0.25);  // Dark background for text readability
  }

  h1 {
    text-transform: uppercase;
    font-size: 3rem;
    text-shadow: 2px 2px #232323;  // Adds a shadow to make the text stand out
  }

  h3 {
    font-size: 1.5rem;
    text-shadow: 2px 2px #232323;
  }
}

.hero-btns {
  display: flex;
  justify-content: center;
  margin-top: 2rem;  // Adds space above the button

  #hero-btn {
    color: whitesmoke;
    text-decoration: none;
  }

  #hero-btn:hover {
    color: #232323;
  }
}

// Optional animation for the background image scaling (if you want)
@keyframes increase {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

// Responsive design for smaller screens
@media screen and (max-width: 750px) {
  .text-container {
    margin: 35% 10% 25% 10%;  // Adjusts the position on smaller screens
  }
}



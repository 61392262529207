@import '../../../../../variables';

.contact-container {
    min-height: 70vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin-bottom: 1.5rem;
        text-align: center;
    }

    p {
        font-size: 120%;
        line-height: 1.5;
        margin-bottom: 1rem;
    }
}

.video-container {
    margin: 0 auto;
    
    video {
        // max-height: 30vh;
        margin-top: 1rem;
        width: 70vw;
    }

    // @media screen and (min-width: 600px) {
    //     video {
    //         height: 35vh;
    //     }

}
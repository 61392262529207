@import '../../../../../variables';

h1 {
    text-align: center;
}

.question {
    font-weight: bolder;
    margin-top: 2rem;
    margin-bottom: 0.75rem;
    font-size: 122%;
}

p {
    line-height: 1.3;
}

.bold {
    font-weight: bold;
    margin-top: 0.5rem;
}

.section-picture {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .qa-picture {
        max-width: 700px;
    }
}

.qa-container {
    max-width: 900px;
    margin: 0 auto;
}


@media screen and (min-width: 1200px) {
    .qapage-container {
        padding: 2rem 9rem 6rem 9rem;
    }
}

@media (max-width: 1000px) {
    .qapage-container {
        min-height: 70vh;
        padding: 2rem 3rem 3rem 3rem;
    
        .qa-picture {
            max-width: 550px;
            justify-content: center;
            overflow: hidden;
        } 

        .qapage-container {
            padding: 2rem 6rem 6rem 6rem;
        
            p {
                font-size: 120%;
            }
        
            .qa-picture {
                max-width: 700px;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .qapage-container {
        padding: 2rem;

        .qa-section {
            display: flex;
            flex-wrap: wrap;

            .section-picture {
                overflow: hidden;
            }

            .qa-picture {
                max-width: 350px;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .qa-picture {
        max-width: 250px;
    }
}

// :root {
//     --primary: #fff;
// }

.btn {
    padding: 0.5em 1.2em;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;  
    font-size: 1.5rem;
    font-family: 'Josefin Sans', sans-serif;
}

.btn-primary {
    // background-color: #fff;
    background-color:#15341c;
    color: #fff;
    border: 1px solid #232323;
    transition: all 0.5s ease-in-out;

    a {
        text-decoration: none;
    }
}

.btn--outline {
    // color: #232323;
    background-color: #fff;
    padding: 0.5em 1.2em;
    border: 1px solid #232323;
    transition: all 0.5s ease-in-out;

    a {
        text-decoration: none;
        color: #15341c;
    }
}

.btn--outline:hover {
    background-color: #4daa57;
    transition: all 0.5s ease-in-out;

    a {
        color: #fff;
    }
}

.btn--med {
    padding: 0.5em 1.2em;
}

.btn--lge {
    padding: 0.8em 1.4em;
    font-size: 1.2em;
}

.btn-primary:hover {
    background-color: #4daa57;
    color: #fff;
    transition: all 0.5s ease-in-out;
}
@import '../../../../../../variables';

.swamp-container {
    min-height: 50vh;
    padding: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;

    // h2 {
    //     padding-top: 1.5rem;
    //     text-align: center;
    //     font-size: 2.5rem;
    //     color: $dark-opaque;
    //     text-shadow: 2px 2px 2px #232323;
    // }  
}

.swamp-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 90vw;
}

.swamp-text {
    max-width: 400px;
    // width: 500px;
    // margin-left: 1.5rem;
    // margin-bottom: 1rem;
    margin: 0 1rem 2rem 1rem;

    h2 {
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }
}

.swamp-img {
    max-width: 100%;
    margin: 0 1rem 1rem 1rem;

    img {
        max-width: 500px;
    }
}


